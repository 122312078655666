<template>
    <article class="columns">
      <section class="column">
        <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
        <div class="modal-background"></div>
        <div class="modal-card scrollable" style="width: auto">
            <!-- <form action="" class="box" @submit.prevent="submitForm"> -->
            <header class="modal-card-head">
                <span class="modal-card-title has-text-centered">
                   {{ (addingNewProduct ? "ADD NEW PRODUCT" : "UPDATE PRODUCT") }} 
                </span>
            <button class="delete is-danger" type="button" @click="cancelForm">Cancel</button>
            </header>
            <section class="modal-card-body">
                <div class="columns">
                    <div class="column is-6 has-text-weight-bold" v-if="scannedProduct.upc">
                        <form ref="form" @submit.prevent="handleSubmit">
                            <div class="columns is-mobile veecli-table">
                                <div class="column is-4 has-text-weight-bold">
                                    UPC
                                </div>
                                <div class="column is-8 ">
                                    {{ scannedProduct.upc}}-{{ zeroPad(scannedProduct.upcModifier,3) }}
                                    <span v-if="!addingNewProduct">
                                        <span v-if="scannedProduct.active">
                                            🟢
                                        </span>
                                        <span v-else>
                                            🔴
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="columns is-mobile veecli-table" v-if="scannedProduct.sku">
                                <div class="column is-3 has-text-weight-bold">
                                    SKU
                                </div>
                                <div class="column is-9">
                                    {{ scannedProduct.sku }}
                                </div>
                            </div>
                            <div class="columns is-mobile veecli-table-input">
                                <div class="column is-4 ">
                                    Name
                                </div>
                                <div class="column is-8">
                                    <span>
                                        <VeeInput
                                            refname="productName"
                                            ref="productName"
                                            name="scannedProduct.name"
                                            type="text"
                                            v-model="scannedProduct.name"
                                            :value="scannedProduct.name"
                                            width="15em"
                                            :required="true"
                                            :editable="allowEdit"
                                            >
                                        </VeeInput>
                                        <!-- v-if="addingNewProduct" -->
                                        <!-- <span v-else>
                                            {{ scannedProduct.name }}
                                        </span> -->
                                    </span>
                                </div>
                            </div>                                                        
                            <div class="columns is-mobile has-text-right veecli-table-input">
                                <div class="column is-4 has-text-weight-bold">
                                    Department
                                </div>
                                <div class="column is-8">
                                    <b-select class="veecli-select" 
                                        @input="departmentChanged"
                                        v-model="scannedProduct.departments">
                                        <option v-for="item in departments" 
                                            :key="item.id" 
                                            :value="item">
                                                {{ item.name }}
                                        </option>
                                    </b-select>
                                    <!-- v-if="addingNewProduct" :disabled="!addingNewProduct"
                                    <span v-else>
                                        {{ (scannedProduct.departments ? scannedProduct.departments.name : "") }}
                                    </span> -->
                                </div>
                            </div>

                            <div class="columns is-mobile veecli-table">
                                <div class="column is-7">
                                    Food Stamp Allowed                               
                                    <b-switch 
                                        size="is-small"
                                        v-model="scannedProduct.foodStamp">
                                    </b-switch>
                                </div>
                                <!-- <div class="column">
                                    Sp. Discount                                
                                    <b-switch 
                                        size="is-small"
                                        v-model="scannedProduct.specialDiscount">
                                    </b-switch>
                                </div> -->
                            </div>
                            <div class="columns is-mobile bottom-line">
                                <div class="column">
                                    Tax Rate(s) <b-switch size="is-small" 
                                        v-model="isTaxable" 
                                        @input="taxableChanged">                                        
                                    </b-switch>
                                    <b-select
                                        multiple
                                        size="is-small"
                                        native-size="2"
                                        :disabled="!isTaxable"
                                        @input="setTaxable"
                                        v-model="scannedProduct.taxRatesIdArr">
                                        <option
                                            v-for="taxRate in posConfig.taxRates"
                                            :value="taxRate.id"
                                            :key="taxRate.id">
                                            {{ taxRate.name }} - {{ taxRate.rate }}%
                                        </option>
                                    </b-select>
                                </div>

                                <div class="column primary-select has-text-right">
                                    Age Validation <b-switch size="is-small" 
                                        v-model="isAgeValidation" 
                                        @input="ageValidationChanged">                                        
                                    </b-switch>
                                    <b-select
                                        multiple
                                        size="is-small"
                                        native-size="2"
                                        :disabled="!isAgeValidation"
                                        v-model="scannedProduct.idCheckIdArr">
                                        <option
                                            v-for="ageItem in posConfig.ageValidations"
                                            :value="ageItem.id"
                                            :key="ageItem.id">
                                            {{ ageItem.name }} - {{ ageItem.rate }}%
                                        </option>
                                    </b-select>
                                </div>
                            </div>
                            <!-- <div class="columns is-mobile">
                                <div class="column is-6">
                                     Blue Law {{ scannedProduct.blueLawIdArr }}
                                    <b-select
                                        multiple
                                        size="is-small"
                                        native-size="3"
                                        v-model="scannedProduct.blueLawIdArr">
                                        <option
                                            v-for="blueLaw in posConfig.blueLaws"
                                            :value="blueLaw.id"
                                            :key="blueLaw.id">
                                            {{ blueLaw.name }} 
                                        </option>
                                    </b-select>
                                </div>
                            </div> -->
                            <div class="columns is-mobile veecli-table-input  has-text-weight-bold">
                                <div class="column is-6">
                                {{ scannedProduct.blueLawSysIds }}
                                </div>
                            </div>

                            <div class="columns is-mobile veecli-table-input  has-text-weight-bold">
                                <div class="column is-6">
                                {{  scannedProduct.fees }}
                                </div>
                            </div>
                           
                            <div class="columns is-mobile veecli-table-input  has-text-weight-bold">
                                <div class="column is-6">
                                    Current Price:
                                </div>
                                <div class="column has-text-right">
                                    {{ (scannedProduct.priceCurrent ? scannedProduct.priceCurrent : 0) | formatCurrency}}
                                </div>
                            </div>

                            <div class="columns spacer-row is-hidden-mobile">
                                <div class="column has-text-white">
                                    ------------------------------------------------------------------
                                </div>
                            </div>                                                                                   
                        </form>
                    </div>
                    <div class="vertical-line"></div>
                    <div class="column is-6">
                        <div class="columns veecli-table  has-text-weight-bold">
                            <div class="column is-12 is-size-6">
                                Profit Method - {{ getSetting('profitCalculationMethod') }} - 
                                {{ deptProfitPercentage }}% of {{ (getSetting('profitCalculationMethod') == 'Markup') ? "Cost" : "Sales Price" }}.
                            </div>
                        </div>
                        <div class="columns is-mobile veecli-table-input">
                            <div class="column is-9 has-text-weight-bold is-size-6" v-if="getSetting('profitCalculationMethod') == 'Markup'">
                                Calculated Cost 
                            </div>
                            <div class="column is-9 has-text-weight-bold is-size-6" v-else>
                                Calculated Cost ({{ 100-deptProfitPercentage }}% of Price)
                            </div>
                            <div class="column is-3 has-text-right has-text-weight-bold">
                                {{ priceToCost | formatCurrency }}
                            </div>                                
                        </div> 
                        <div class="columns is-mobile veecli-table-input">
                            <div class="column is-7 is-size-6 has-text-weight-bold">
                                Actual Purchase Cost
                            </div>
                            <div class="column is-5 has-text-right">
                                <VeeInput
                                    type="currency" 
                                    v-model="scannedProduct.averageCost"
                                    name="averageCost"
                                    :value="scannedProduct.averageCost"
                                    :countryCurrencyCode="countryCurrencyCode"
                                    :editable="allowEdit && scannedProduct.upc != ''"
                                    :required="false"
                                    />
                            </div>                                
                        </div>
                        <div class="columns is-mobile">
                            <div class="column is-12 has-text-weight-bold has-text-centered is-size-5">
                                Suggested Price
                            </div>
                        </div>
                        <div class="columns is-mobile veecli-table">
                            <div class="column is-9 has-text-weight-bold is-size-6">
                                Price ({{ deptProfitPercentage }}% of Cost)
                                <b-button
                                    class="thin-button"
                                    type="is-primary"
                                    icon-left="arrow-down"
                                    :disabled="!scannedProduct.averageCost"
                                    size="is-small"
                                    @click="copyToPrice('markup')"/>
                                <b-icon type="is-success" icon="check" v-if="getSetting('profitCalculationMethod') == 'Markup'"></b-icon>
                            </div>
                            <div class="column is-3 has-text-right has-text-weight-bold">
                                {{suggestedMarkupPrice | formatCurrency}}
                            </div>                            
                        </div>
                        <div class="columns is-mobile veecli-table">
                            <div class="column is-9 has-text-weight-bold is-size-6">
                                Price ({{ deptProfitPercentage }}% of Sales)
                                <b-button
                                    class="thin-button"
                                    type="is-primary"
                                    icon-left="arrow-down"
                                    :disabled="!scannedProduct.averageCost"
                                    size="is-small"
                                    @click="copyToPrice('margin')"/>
                                <b-icon type="is-success" icon="check" v-if="getSetting('profitCalculationMethod') == 'Margin'"></b-icon>
                            </div>
                            <div class="column is-3 has-text-right has-text-weight-bold">
                                {{suggestedMarginPrice | formatCurrency}}
                            </div>                            
                        </div>

                        <div class="columns is-mobile has-text-weight-bold new-price">
                            <div class="column is-5">
                                New Price: 
                            </div>                                                                
                            <div class="column has-text-right">
                                <VeeInput
                                    refname="productPrice"
                                    ref="productPrice"
                                    type="currency" 
                                    v-model="scannedProduct.price"
                                    name="newPrice"
                                    :value="scannedProduct.price"
                                    :countryCurrencyCode="countryCurrencyCode"
                                    :editable="allowEdit && scannedProduct.upc != ''"
                                    :required="true"
                                    @input="calculateCost(scannedProduct.price)"
                                    />
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
    
            <footer class="modal-card-foot">
                <b-button 
                    type="is-success has-text-weight-bold" 
                    :disabled="saveDisabled"
                    @click="saveUpdatedProduct">
                    {{ ( addingNewProduct ? "Add New Product" : "Update Product" ) }}
                </b-button>
                <b-button class="mr-3" type="is-default"  @click="cancelForm">Cancel</b-button>
                <div  class="is-size-7 is-hidden-mobile">
                    <div v-if="addingNewProduct && tagActiveAddProduct"> 
                        <b-tag closable type="is-danger is-light"  @close="closeAddProductTag">
                            Product Not Found. Confirm Scanned Code is correct and Add New Product.
                        </b-tag>
                    </div>

                    <div v-if="tagActiveEnterCost"> 
                        <b-tag closable type="is-info is-light" @close="closeCostTag">
                            May enter Purchase Cost to calculate suggested selling price. Uses set department profit margin %.
                        </b-tag>
                    </div>
                </div>
            </footer>
    
        </div>
        </div>
      </section>
    </article>
</template>
    
    
<script>
    
    import UserStore from "../../../../store/UserStore"
    import VeeInput from "../../../../components/app/VeeInput.vue"
    
    export default {
        components: {
            VeeInput,
        },
    
        props: {
             action: String,
             allowEdit: Boolean,
             isShowDialog: Boolean,
             scannedProduct: Object,
             addingNewProduct: Boolean,
             departments: Array,
             posConfig: Object
        },

        directives: {
            focus: {
                // directive definition
                inserted: function (el) {
                    el.focus()
                }
            }
        },
    
        data() {
            return {
                tagActiveAddProduct: true,
                tagActiveEnterCost: true,
                trueValue: true,
                falseValue: false,
                zeroValue: 0,
                isTaxable: 0,
                isAgeValidation: 0,
                email: '',
                role: '',
                openOnFocus: true,
                keepFirst: true,
                clearable: false,
                data: [],
                isFetching: false,
                vendorName: '',
                employees: [],
                employeeName: '',
                employeeFirstName:'',
                name: '',
                page: 1,
                totalPages: 1,
                paymentMethod: "Cash",
                priceToCost: 0,
                countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
                user: []
            }
        },
    
        methods: {

            openDialog() {
                this.$nextTick(()=> {
                    let myref = this.$refs[`productPrice`].$refs[`productPrice`]
                    if (this.addingNewProduct) {
                        myref = this.$refs[`productName`].$refs[`productName`]
                        this.departmentChanged()
                    }

                    this.calculateCost(this.scannedProduct.price)
                    myref.focus()
                })
            },
    
            closeDialog() {
    
                this.$emit('cancel-product-form')
    
            },

            setTaxable() {
                if (this.scannedProduct.taxRatesIdArr == null) {
                    this.scannedProduct.idCheckIdArr = []
                    this.isAgeValidation = false
                }
                if (this.scannedProduct.idCheckIdArr == null) {
                            this.scannedProduct.idCheckIdArr = []
                            this.isAgeValidation = false
                        }
                if (this.scannedProduct.taxRatesIdArr.length == 0) {
                    this.scannedProduct.isTaxable = false
                } else {
                    this.scannedProduct.isTaxable = true
                }
            },
    
            cancelForm() {
                this.closeDialog()
            },

            saveUpdatedProduct() {
                if (this.getSetting('profitCalculationMethod')=='Markup') {
                    this.scannedProduct.suggestedSellPrice = this.suggestedMarkupPrice
                } else {
                    this.scannedProduct.suggestedSellPrice = this.suggestedMarginPrice
                }
                this.$emit('save-updated-product')
            },

            calculateCost(price) {
                var cost = this.calculateMercandiseCost(price,this.deptProfitPercentage ) 
                this.priceToCost = cost
            },

            copyToPrice(type) {
                if (type=='markup') {
                    this.scannedProduct.price = this.suggestedMarkupPrice
                } else {
                    this.scannedProduct.price = this.suggestedMarginPrice
                }
                this.calculateCost(this.scannedProduct.price)
            },

            closeAddProductTag() {
                this.tagActiveAddProduct = false
            },

            closeCostTag() {
                this.tagActiveEnterCost = false
            },

            departmentChanged() {

                this.scannedProduct.foodStamp = this.scannedProduct.departments.allowFoodStamps
                this.scannedProduct.fractionalQty = this.scannedProduct.departments.allowFractionalQty
                this.scannedProduct.specialDiscount = this.scannedProduct.departments.allowSpecialDiscount
                this.scannedProduct.blueLawSysIds = this.scannedProduct.departments.blueLawSysIds
                this.scannedProduct.fees = this.scannedProduct.departments.fees
                this.scannedProduct.idCheckSysIds = this.scannedProduct.departments.idCheckSysIds
                this.scannedProduct.taxRateSysIds = this.scannedProduct.departments.taxRateSysIds
                
                // this.isTaxable = (this.scannedProduct.taxRateSysIds === "" ? 0 :1)
                
                if (!this.scannedProduct.taxRateSysIds) {
                    this.scannedProduct.taxRatesIdArr = []
                    this.isTaxable = false
                } else {
                    if (this.posConfig.taxRates) {
                        this.scannedProduct.taxRatesIdArr = this.sysIdListToIdArray(this.posConfig.taxRates,this.scannedProduct.taxRateSysIds)
                        this.isTaxable = true
                        if (this.scannedProduct.taxRatesIdArr == null) {
                            this.scannedProduct.taxRatesIdArr = []
                            this.isTaxable = false
                        }
                    } else {
                        this.scannedProduct.taxRatesIdArr = []
                        this.isTaxable = false
                    }
                }

                // this.isAgeValidation = (this.scannedProduct.idCheckSysIds === "" ? 0 :1)

                if (!this.scannedProduct.idCheckSysIds) {
                    this.scannedProduct.idCheckIdArr = []
                    this.isAgeValidation = false
                } else {
                    if (this.posConfig.ageValidations) {
                        this.scannedProduct.idCheckIdArr = this.sysIdListToIdArray(this.posConfig.ageValidations,this.scannedProduct.departments.idCheckSysIds)
                        this.isAgeValidation = true
                        if (this.scannedProduct.idCheckIdArr == null) {
                            this.scannedProduct.idCheckIdArr = []
                            this.isAgeValidation = false
                        }
                    } else {
                        this.scannedProduct.idCheckIdArr = []
                        this.isAgeValidation = false
                    }
                }

                this.taxableChanged()
                // console.log("IdCheck",this.scannedProduct.idCheckSysIds, this.scannedProduct.idCheckIdArr)

                this.scannedProduct.open = false                
            },

            ageValidationChanged() {
                if (!this.isTaxable) {
                    this.scannedProduct.idCheckSysIds = ""
                    this.scannedProduct.idCheckIdArr = []
                }
            },            

            taxableChanged() {
                if (!this.isTaxable) {
                    this.scannedProduct.taxRateSysIds = ""
                    this.scannedProduct.taxRatesIdArr = []
                }
            }
        },
    
        computed: {
            isDisabled() {
                return false
            },

            isFoodStampOk() {
                var foodStampOk = (this.scannedProduct.departments.storeMapping == "Grocery" || 
                    this.scannedProduct.departments.storeMapping == "InsideSales") 
                return foodStampOk
            },

            suggestedMarkupPrice() {
                return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarkupSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
            },

            suggestedMarginPrice() {
                return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarginSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
            },

            deptProfitPercentage() {
                return (this.scannedProduct && this.scannedProduct.departments ? this.scannedProduct.departments.percentage : 0)
            },
            
            suggestedPrice() {
                if (this.getSetting('profitCalculationMethod') === "Margin") {
                    return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarginSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
                } else {
                    return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarkupSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
                }
            },

            saveDisabled() {
                var okToSave = true
                if (!this.scannedProduct) {
                    okToSave = false
                }
                if (this.scannedProduct.price <= 0) {
                    okToSave = false
                }
                if (this.scannedProduct.price == this.scannedProduct.priceCurrent) {
                    okToSave = false
                }
                if ((this.scannedProduct.name == "")) {
                    okToSave = false
                }

                return !okToSave

            }
        },
    
        mounted() {
            this.user = this.getUser()
        }
    
    }
</script>

<style>
.vertical-line {
  border-left: 2px solid lightgray;
  margin-top: 5px;
  /* height: 2500px; */
  margin-bottom: 5px;
}

.bottom-line {
    border-bottom: 0.5px solid gray;
    margin-left: 3px;
    margin-right: 3px;
}

.new-price {
    /* border-bottom: 0.5px solid gray; */
    box-shadow: 2px 2px gray;
    background-color:bisque;
    margin-top: 10px;
    margin-left: 3px;
    margin-right: 3px;
    font-size: large;
}

.spacer-row {
    height: 2px;
}

.thin-button {
    height: 20px;
    width: 30px;
}

.primary-select input::selection {  
    background: #f7a494; 
}

</style>