<template>
<div class="section">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowEditDialog }">
        <div class="modal-background"></div>
        <b-modal
            v-model="isShowEditDialog"
            has-modal-card
            full-screen
            :can-cancel="false"
            @close="closeDialog"
            >
            <VeeSavePane
                :row1="'Price Updated for ' + productsUpdated.length + ' Products.'"
                :cancelEnable="productsUpdated.length > 0"
                :saveEnable="productsUpdated.length > 0"
                :isEditing="productsUpdated.length > 0"
                @cancel-edit="cancelAllUpdates()"
                @save-edit="saveAllUpdates()"
                >

            </VeeSavePane>
            <div class="modal-card scrollable">
                <header class="modal-card-head">
                    <p class="modal-card-title has-text-center">SCAN AND UPDATE PRODUCTS</p>&nbsp;&nbsp;
                    <!-- <button type="button" @click="closeDialog" v-if="productsUpdated.length == 0">CLOSE</button> -->
                    <div class="button is-info has-text-white has-text-weight-bold is-small"  @click="closeDialog" v-if="productsUpdated.length == 0">
                        <i class="delete"></i>&nbsp;&nbsp;CLOSE
                    </div>
                </header>
                <section class="modal-card-body">
                    <div class="columns has-text-weight-bold">
                        <div class="column is-3 box">
                            <div class="column has-background-grey-lighter has-text-centered has-text-default is-size-5 has-text-weight-bold">
                                Scan Bar Code/Type all numbers<br>                            
                            </div>
                            <div>&nbsp;</div>
                            <div v-show="inputMethod == 'Scan Product Bar Code'">

                                <b-field>
                                    <b-input class="scanInput has-text-weight-bold"
                                        v-model="scannedProductBarCode" 
                                        v-focus
                                        autofocus
                                        icon-pack="fas"
                                        icon="barcode"
                                        ref="scannedProductBarCode"
                                        @keyup.native="onKeyUpNumberOnly"
                                        @keyup.native.enter="processScannedInput"
                                        @focus="$event.target.select()"
                                        placeholder="Scan Product Bar Code">
                                        >
                                    </b-input>
                                </b-field> 
                            </div>
                            <!-- <div class="button is-info is-light has-text-black has-text-weight-bold"  @click="closeDialog" v-if="productsUpdated.length == 0">
                                <i class="delete"></i>&nbsp;&nbsp;CLOSE
                            </div> -->
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="panel-block has-text-weight-bold">
                                <b-table class="control" 
                                    :data='productsUpdated'
                                    :paginated="isPaginated"
                                    :per-page="perPage"
                                    :current.sync="page"
                                    paginationPosition="both"
                                    sortIcon="arrow-up"
                                    sortIconSize="is-small"
                                    :row-class="getRowClass"
                                    :default-sort="sortField"
                                    :defaultSortDirection='sortOrder'
                                    >

                                <template>
                                    <b-table-column class="is-2 has-text-right" field="Edit" label="Edit" v-slot="props">
                                        <b-button  
                                            type="is-primary is-small"  
                                            v-on:click="editProductUpdate(props.row)" 
                                            outlined rounded
                                            icon-right="edit">
                                        </b-button>
                                    </b-table-column>
                                    <b-table-column  v-slot="props">
                                        <b-button 
                                            outlined rounded 
                                            type="is-danger is-small"
                                            @click="deleteProductUpdate(props.row)"
                                            tabindex="-1"
                                            icon-right="trash-alt">
                                        </b-button>
                                    </b-table-column>
                                    <b-table-column field="upc" label="UPC" searchable sortable sorticon v-slot="props">
                                        {{ props.row.upc }}-{{ zeroPad(props.row.upcModifier,3) }}
                                        <b-tag type="is-warning" v-if="!props.row.id">New</b-tag>
                                    </b-table-column>

                                    <b-table-column class="is-2" field="barCode"  v-slot="props" label="Barcode" v-if="isShowBarcode">
                                        <vue-barcode 
                                            v-bind:value="props.row.upc" 
                                            height="20" 
                                            width="1" 
                                            displayValue="false">
                                            N/A
                                        </vue-barcode>
                                    </b-table-column> 
                                    <b-table-column field="name" label="Name" searchable sortable sorticon  v-slot="props">
                                    {{ props.row.name }}
                                    </b-table-column>
                                    <b-table-column field="taxRateSysIds" label="TaxSysId" v-slot="props">
                                        {{ props.row.taxRateSysIds  }}
                                    </b-table-column>
                                    <b-table-column field="taxRatesIdArr" label="TaxArr" v-slot="props">
                                        {{ props.row.taxRatesIdArr  }}
                                    </b-table-column>
                                    <b-table-column field="departments.name" label="Dept" searchable sortable sorticon  v-slot="props">
                                    {{ ( props.row.departments ? props.row.departments.name : 'n/a' ) }}
                                    </b-table-column>
                                    <b-table-column field="departments.storeMapping" label="Mapping" searchable sortable sorticon  v-slot="props">
                                    {{ ( props.row.departments ? props.row.departments.storeMapping : 'n/a' ) }}
                                    </b-table-column>
                                    <!-- <b-table-column numeric  field="averageCost" label="Cost" v-slot="props">
                                        {{ (props.row.averageCost ? props.row.averageCost : 0) | formatCurrency }} 
                                    </b-table-column>                                     -->
                                    <b-table-column field="departments.percentage" label="Margin" sortable sorticon  v-slot="props">
                                    {{ ( props.row.departments ? props.row.departments.percentage +"%" : 'n/a' ) }}
                                    </b-table-column>
                                    <!-- <b-table-column numeric  field="suggestedSellPrice" label="Suggested Price" v-slot="props">
                                        {{ (props.row.suggestedSellPrice ? props.row.suggestedSellPrice : 0) | formatCurrency }} 
                                    </b-table-column>  -->
                                    <b-table-column numeric field="priceCurrent" label="Price Current" sortable sorticon  v-slot="props">
                                        {{ (props.row.priceCurrent ? props.row.priceCurrent : 0) | formatCurrency }}
                                    </b-table-column>
                                    <b-table-column numeric field="price" label="New Price" v-slot="props" sortable sorticon>
                                        {{ props.row.price | formatCurrency }}
                                    </b-table-column>
                                    <b-table-column field="active" label="Active" v-slot="props">
                                        <i class="fas fa-check-circle  has-text-success" v-if="props.row.active"></i>
                                        <i class="fas fa-dot-circle  has-text-danger" v-else></i>
                                    </b-table-column>
                                </template>
                                
                                <template slot="empty">
                                    <data-loading 
                                        :isLoading="isLoading" 
                                        :isFullPage="isFullPage"> 
                                    </data-loading>
                                </template>

                                <template slot="top-left">
                                    <div  class="is-size-6">
                                        Products per page: 
                                        <select class="is-size-6" name="perPage" v-model="perPage" @change="onPageChange(1)">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>                    
                                    </div>
                                </template>

                                <template slot="header">
                                    
                                </template>
                                <template slot="footer">
                                    
                                </template>    

                                </b-table>  

                            </div>
                        </div>
                    </div> 
                </section>
            </div>
        </b-modal>
        <product-update-form
            ref="productUpdateForm"
            :isShowDialog="isShowDialog"
            :allowEdit="allowEdit"
            :scannedProduct="scannedProduct"
            :addingNewProduct="addingNewProduct"
            :departments="departments"
            :posConfig="posConfig"
            @suggested-price="suggestedPrice"
            @dept-profit-percentage="deptProfitPercentage"
            @cancel-product-form="cancelProductForm"
            @save-updated-product="saveUpdatedProduct"
            >

        </product-update-form>
        <product-scan-multiple
            ref="productMultipleForm"
            :isShowMultipleDialog="isShowMultipleDialog"
            :scannedProductsMultiple="scannedProductsMultiple"
            @product-selected="productSelected"
            @cancel-product-multiple="cancelProductMultiple">

        </product-scan-multiple>
    </div>
</div>
    
</template>

<script>

import axios from 'axios'
import UserStore from '../../../../store/UserStore'
import ProductUpdateForm from "./ProductUpdateForm"
import ProductScanMultiple from "./ProductScanMultiple"
import DataLoading from '../../../../components/app/DataLoading'
import VeeSavePane from "../../../../components/app/VeeSavePane"
import VueBarcode from 'vue-barcode'

export default {

    props: {
        isShowEditDialog: Boolean,
        user: Object,
        departments: Array,
    },

    directives: {
        focus: {
            // directive definition
            inserted: function (el) {
                el.focus()
            }
        }
    },
    
    components: {
        DataLoading,
        ProductUpdateForm,
        ProductScanMultiple,
        VeeSavePane,
        VueBarcode
    },

    data() {
        return {
            isLoading: false,
            isPaginated: true,
            perPage: 50,
            isEditable: true,
            isShowBarcode: false,
            page: 1,
            isFullPage: false,
            sortField: 'name',
            sortOrder: 'asc',
            allowEdit: true,
            addNewProductEnabled: true,
            addingNewProduct: false,
            scanFieldEditable: true,
            scannedProductBarCode: "",
            UPCApiURL: "https://api.upcdatabase.org/product/",
            UPCApiKey: "74B81E896F88A482A10072F5566F188C",
            scannedProduct: {
                "id": null,
                "name": null,
                "price": 0.00,
                "priceCurrent": 0.00,
                "departments": {}
            },
            productsUpdated: [],
            trueValue: true,
            isShowDialog: false,
            isShowMultipleDialog: false,
            scannedProductsMultiple: [],
            inputMethod: "Scan Product Bar Code",
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
            soundProductFound: new Audio('/sounds/ScanBookFoundOnSale.mp3'),
            soundProductNotFound: new Audio('/sounds/ScanBookFoundInSafe.mp3'),
            posConfig: {},
            emptyProduct: {
                "active": true,
                "altImageURL": "",
                "averageCost": 0,
                "blueLawSysIds": null,
                "blueLawIdsArr": [],
                "brand": "",
                "color": "",
                "costMethod": "FIFO",
                "currency": "",
                "departments": {
                    "active": true,
                    "allowFoodStamps": false,
                    "allowFractionalQty": false,
                    "allowSpecialDiscount": false,
                    "blueLawSysIds": null,
                    "departmentType": null,
                    "fees": null,
                    "fuelDepartment": false,
                    "id": null,
                    "idCheckSysIds": null,
                    "maxAmount": 0,
                    "minAmount": 0,
                    "moneyOrderDepartment": false,
                    "name": "",
                    "negativeDepartment": false,
                    "percentage": 10,
                    "prohibitManualDiscount": false,
                    "promptSerialNumber": false,
                    "storeMapping": "",
                    "systemId": null,
                    "systemName": "",
                    "taxRateSysIds": "",
                    "transactionQuantityLimit": 0
                },
                "description": "",
                "dimension": "",
                "fees": null,
                "foodStamp": false,
                "fractionalQty": false,
                "groupCode": "",
                "groupCodeIndex": null,
                "id": 0,
                "idCheckSysIds": null,
                "imageURL": "",
                "itemCachedOnPOS": true,
                "itemDeptFoodstampable": false,
                "itemDeptSpecialDiscApplies": false,
                "lottoTrackingRequired": false,
                "manufacturer": "",
                "marginPercentage": 10,
                "maxQtyPerTransaction": 100,
                "maxQtyPerTransactionUom": "Ea.",
                "minimumQuantity": 1,
                "model": "",
                "name": "",
                "notSold": false,
                "onHand": 0,
                "open": false,
                "pCode": 0,
                "price": 0,
                "productCodes": {
                    "active": true,
                    "description": "",
                    "fuelProductCode": false,
                    "id": 0,
                    "name": "",
                    "sysId": 0
                },
                "prohibitManualDisc": false,
                "promo": false,
                "promptSerialNumber": false,
                "rebateAmount": 0,
                "rebateAmtTaxRateSysIds": null,
                "returnable": true,
                "sellUnit": 1,
                "sequence": "",
                "size": "",
                "sku": "",
                "specialDiscount": false,
                "suggestedSellPrice": 0,
                "taxRateSysIds": null,
                "taxRatesIdArr": [],
                "isTaxable": false,
                "blueLawIds": null,
                "blueLawIdArr": [],
                "idCheckIds": null,
                "idCheckIdArr": [],
                "transactionQuantityLimit": 0,
                "upc": "",
                "upcModifier": "0",
                "weight": "",
            }
        }
    },

    methods: {
        openDialog() {
            this.$nextTick(()=> {
                this.$refs.scannedProductBarCode.focus()
            })
        },

        closeDialog() {
            if (this.productsUpdated.length > 0) {
                this.$buefy.dialog.confirm({
                    title: 'Leaving without saving!',
                    message: 'Are you sure you want to discard all changes and leave?',
                    confirmText: 'Discard Changes',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$emit('hide-dialog')
                    }
                })
            } else {
                this.$emit('hide-dialog')
            }

        },

        async fetchPosConfig() {
            this.posConfig = {}
            var url = process.env.VUE_APP_API_ROOT_URL + "stores/"  + this.user.activeStore + "/posConfig"

            await axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    this.posConfig = response.data.data
                    this.posConfig.ageValidations.forEach(age => {
                        age.sysid = age.systemId
                    })
                })
                .catch((err) => {
                    this.$buefy.toast.open({
                        message: "POS Config Data Invalid" + err,
                            type: 'is-danger'
                    }) 

                })


        },

        async fetchProduct(upc) {

            this.scannedProduct = {}

            var url = process.env.VUE_APP_API_ROOT_URL + "stores/"  + this.user.activeStore + "/products/" + upc

            await axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    if (response.data.data.length == 1) {
                        var fetchedProduct = response.data.data[0]
                        if (fetchedProduct.upc === upc) {
                            this.scannedProduct = fetchedProduct                            
                            this.scannedProduct.priceCurrent = this.scannedProduct.price
                            this.productFound()

                        }
                    } else {
                        if (response.data.data.length > 1) {
                            this.scannedProductsMultiple = response.data.data
                            this.productMultipleFound()
                        } else {
                            this.productNotFound(upc)
                        }
                    }
                })
                .catch((err) => {
                    if (err.response.status == 404) {
                       this.productNotFound(upc)
                    } else {
                       this.$buefy.toast.open({
                           message: "Product UPC Code: " + upc + " search Failed. " + err,
                               type: 'is-danger'
                       }) 
                   }
                })

        },

        async getProductFromApi(upc) {
            var url = "https://ean-db.com/api/v2/product/" + upc
            var apiToken="eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyNGQ1M2E3NS1jNjBiLTRlMWUtOGI1OS1hYWU3MDI5MTY4YzQiLCJpc3MiOiJjb20uZWFuLWRiIiwiaWF0IjoxNzIzNTYwNTY2LCJleHAiOjE3NTUwOTY1NjYsImlzQXBpIjoidHJ1ZSJ9.OEFWUBfU4s4fOPsPCr_M10pJB-qMpCQYAyGqr5wT9wGal9Z1v8y4qBcAkp3tuBkV4Rr3bhyzNBs7erLgEjyftQ"

            var product = {
                "barcode": "",
                "name": "",
                "categories": "",
                "manufacturer": "",
                "images": []
            }
            axios.get(url,{
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + apiToken
                    },                   
                })
                .then(response => {
                    if (response.length > 0) {
                        product = {
                            "barcode": response.data.barcode,
                            "name": response.data.titles.en,
                            "categories": response.data.categories[0].titles.en,
                            "manufacturer": response.data.manufacturer.titles.en,
                            "images": response.data.images
                        } 
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                return product
        },
 
        async getProductFromApi2(upc) {
            const axios = require('axios');
                if (!upc) {
                    console.log("No UPC")
                }

                const options = {
                method: 'GET',
                url: 'https://big-product-data.p.rapidapi.com/gtin/96619120444',
                // url: 'https://big-product-data.p.rapidapi.com/gtin/'+upc,
                headers: {
                    'X-RapidAPI-Key': 'c15a51d82cmsh3cc18868629941cp1c1104jsn5d95fa2f82e8',
                    'X-RapidAPI-Host': 'big-product-data.p.rapidapi.com'
                }
                };

                try {
                    const response = await axios.request(options);
                    console.log(response.data);
                    return response.data
                } catch (error) {
                    console.error(error);
                }
        },

        async getProductFromApi1(upc) {
            var url = this.UPCApiURL + upc + "?apikey="+this.UPCApiKey
            
            await axios.get(url,{
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
                        },
                    })
                .then( (response) => {
                    return response.data
                })
        },

        setTaxable() {
            if (this.scannedProduct.taxRatesIdArr.length == 0) {
                this.scannedProduct.isTaxable = true
            } else {
                this.scannedProduct.isTaxable = false
            }
        },

        productFound() {
            this.$buefy.toast.open({
                message: "Product Found with UPC Code: " + this.scannedProduct.upc + " Name: " + this.scannedProduct.description,
                    type: 'is-success'
            })
            if (this.scannedProduct.taxRateSysIds) {
                this.scannedProduct.taxRatesIdArr = this.sysIdListToIdArray(this.posConfig.taxRates,this.scannedProduct.taxRateSysIds)
            } else {
                this.scannedProduct.taxRatesIdArr = []
            }

            if (this.scannedProduct.idCheckSysIds) {
                this.scannedProduct.idCheckIdArr = this.sysIdListToIdArray(this.posConfig.ageValidations,this.scannedProduct.idCheckSysIds)
            } else {
                this.scannedProduct.idCheckIdArr = []
            }

            if (this.scannedProduct.blueLawSysIds) {
                this.scannedProduct.blueLawIdsArr = this.sysIdListToIdArray(this.posConfig.blueLaws,this.scannedProduct.blueLawSysIds)
            } else {
                this.scannedProduct.blueLawIdsArr = []
            }

            if (this.scannedProduct.feeAddr) {
                this.scannedProduct.blueLawIdsArr = this.sysIdListToIdArray(this.posConfig.blueLaws,this.scannedProduct.blueLawSysIds)
            } else {
                this.scannedProduct.blueLawIdsArr = []
            }

            this.setTaxable()

            // this.scannedProduct.apiresult = this.getProductFromApi(this.scannedProduct.upc)

            // console.log("Product Found",this.scannedProduct.apiresult)

            this.scannedProductBarCode = ""
            this.addingNewProduct = false
            this.isShowDialog=true
            this.$refs.productUpdateForm.openDialog()
        },

        productMultipleFound() {
            this.isShowMultipleDialog = true
            this.scannedProductsMultiple.forEach(product => {
                product.priceCurrent = product.price
            });
            this.$refs.productMultipleForm.openDialog()
        },

        productSelected(selected) {
            this.isShowMultipleDialog=false
            this.scannedProduct=selected
            this.productFound()
        },

        productNotFound(upc) {
            if (this.addNewProductEnabled) {
                this.$buefy.toast.open({
                    message: "Product Not Found with UPC Code: " + this.scannedProduct.upc + ". Complete form to Add New Product.",
                        type: 'is-warning'
                })
                
                // console.log("NotFound", this.getProductFromApi(upc))
                
                this.addingNewProduct = true
                this.scannedProduct = Object.assign({}, this.emptyProduct)
                this.scannedProductBarCode = ""
                this.scannedProduct.upc = upc
                this.scannedProduct.name = ""
                this.scannedProduct.price = 0.00
                this.scannedProduct.priceCurrent = 0.00

                // var newProduct = this.getProductFromApi(upc.substring(1))

                // if (newProduct) {
                //     this.scannedProduct.name = newProduct.title
                //     this.scannedProduct.description = newProduct.description
                //     this.scannedProduct.brand = newProduct.brand                    
                // }
 
                // console.log(newProduct)

                var dept = this.departments.find(item => item.name == "GROCERY")
                if (dept) {
                    this.scannedProduct.departments = dept
                }
                this.isShowDialog=true
                this.$refs.productUpdateForm.openDialog()  
            } else {
                this.$buefy.dialog.alert({
                    title: 'Product Not Found',
                    message: 'Currently VEECLi Only support updating existing products!<br>Please scan another product.',
                    confirmText: 'OK'
                })
                this.scannedProductBarCode = ""
            }
                          
        },

        expandUpcE() {
            var upcEPrefix = this.scannedProductBarCode.substring(0,1)
            var upcENumber = this.scannedProductBarCode.substring(1,7)
            var upcESuffix = this.scannedProductBarCode.substring(7,8)
            var upcEKey = upcENumber.substring(5,6)
            var upcANumber = ""
            
            switch (upcEKey) {
                case "0":
                    upcANumber = upcEPrefix +
                        upcENumber.substring(0,2) +
                        "00000" +
                        upcENumber.substring(2,5) +
                        upcESuffix
                    break;

                case "1":
                    upcANumber = upcEPrefix +
                        upcENumber.substring(0,2) +
                        "10000" +
                        upcENumber.substring(2,5) +
                        upcESuffix
                    break;
                    
                case "2":
                    upcANumber = upcEPrefix +
                        upcENumber.substring(0,2) +
                        "20000" +
                        upcENumber.substring(2,5) +
                        upcESuffix
                    break;

                case "3":
                    upcANumber = upcEPrefix +
                        upcENumber.substring(0,3) +
                        "00000" +
                        upcENumber.substring(3,5) +
                        upcESuffix
                    break;

                case "4":
                    upcANumber = upcEPrefix +
                        upcENumber.substring(0,4) +
                        "00000" +
                        upcENumber.substring(4,5) +
                        upcESuffix
                    break;
            
                default:
                    upcANumber = upcEPrefix +
                        upcENumber.substring(0,5) +
                        "0000" + upcEKey +
                        upcESuffix
                    break;
            }

            this.scannedProductBarCode = upcANumber

        },

        processScannedInput(event) {
            this.onKeyUpNumberOnly(event)
            if ([8,12,13,14].includes(this.scannedProductBarCode.length)) {
                if (this.scannedProductBarCode.length == 8) {
                    this.expandUpcE()
                }
                if (this.scannedProductBarCode.length == 12) {
                    this.scannedProductBarCode = "00" + this.scannedProductBarCode
                }
                var index = this.productsUpdated.findIndex((map) => map.upc === this.scannedProductBarCode)
                if (index > -1) {
                    this.scannedProduct=this.productsUpdated[index]
                    this.editProductUpdate(this.scannedProduct)
                } else {
                    this.fetchProduct(this.scannedProductBarCode)
                }                
            } else {
                this.$buefy.toast.open({
                message: "Invalid UPC Code: " + this.scannedProductBarCode,
                    type: 'is-danger'
                })
                this.cancelProductForm()
            }
        },

        onKeyUpNumberOnly(event) {
            event.target.value = event.target.value.replace(/\D/g,'');
        },

        cancelProductMultiple() {
            this.isShowMultipleDialog=false
        },

        cancelProductForm() {
            this.scannedProductBarCode = ""
            this.scannedProduct = Object.assign({}, this.emptyProduct)
            this.isShowDialog=false
            this.$refs.scannedProductBarCode.focus()
        },

        async saveUpdatedProduct() {
            this.scannedProduct.taxRateSysIds = this.idArrayToSysIdList(this.posConfig.taxRates,this.scannedProduct.taxRatesIdArr)
            this.scannedProduct.idCheckSysIds = this.idArrayToSysIdList(this.posConfig.taxRates,this.scannedProduct.idCheckIdArr)
            var index = this.productsUpdated.findIndex((map) => map.upc === this.scannedProduct.upc)
            console.log("Saving",this.scannedProduct)
            if (index > -1) {
                this.productsUpdated[index] = this.scannedProduct
            } else {
                var product =  Object.assign({}, this.scannedProduct)   
                this.productsUpdated.push(product)
            }
            this.cancelProductForm()
        },

        editProductUpdate(row) {
            this.scannedProduct = row
            if (this.scannedProduct.id) {
                this.productFound()
            } else {
                this.isShowDialog=true
                this.addingNewProduct=true
                this.$refs.productUpdateForm.openDialog()                
            }
        },

        deleteProductUpdate(row) {
            var index = this.productsUpdated.findIndex((map) => map.upc === row.upc)
            if (index > -1) {
                this.productsUpdated.splice(index,1)
            }
        },

        cancelAllUpdates() {
            this.productsUpdated = []
            this.records = []
            this.showOnlyUpdated = false
            this.isPaginated = true
        },

        saveAllUpdates() {
            var url = process.env.VUE_APP_API_ROOT_URL + "/stores/"  + this.user.activeStore + "/products"
            axios.put(url,this.productsUpdated,
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken,
                    'correlation-id': this.$uuid.v1()
                },                   
            })
            .then(response => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Updated Prices Queued.  POS will be updated within 15 mins.'+response.status,
                    position: 'is-top',
                    type: 'is-success'
                })
                this.productsUpdated = []
                this.records = []
                this.showOnlyUpdated = false
                this.isPaginated = true
                this.closeDialog()
            })
            .catch(err => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Failed to Save. Cleared all updates. '+err,
                    position: 'is-top',
                    type: 'is-danger'
                })
            })
        },

        getRowClass(row) {
            if (row.id == null) {
                // return 'has-background-warning-light'
                return ""
            }
        },

    },

    computed: {
        deptProfitPercentage() {
            var perc =  (this.scannedProduct && this.scannedProduct.departments ? this.scannedProduct.departments.percentage : 0)
            if (!perc) {
                return 0
            }
            return perc
        },

        suggestedMarkupPrice() {
            return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarkupSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
        },

        suggestedMarginPrice() {
            return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarginSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
        },

        suggestedPrice() {
            if (this.getSetting('profitCalculationMethod') === "Margin") {
                return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarginSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
            } else {
                return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarkupSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
            }
        }
    },

    mounted() {
        this.fetchPosConfig()
        this.scannedProduct = Object.assign({}, this.emptyProduct)
    }

}
</script>

<style>

/* .scrollable {
    max-height: 200vw;
    width: 400px;
    overflow: auto; 
} */
.scanInput  input[type=text] {
    border: 2px solid red;
    border-radius: 4px;
    background: lightgoldenrodyellow;
}

.veecli-table {
    border-bottom: 0.5px solid gray;
    padding-bottom: 1.5em;
    margin-left: 2px;
    margin-right: 2px;
    height: 1em;
}

.veecli-table-input {
    border-bottom: 0.5px solid gray;
    padding-bottom: 3.5em;
    margin-left: 2px;
    margin-right: 2px;
    height: 1em;
}

.veecli-select select {
    background-color: rgb(250, 249, 246);
    border-color: red;
    box-shadow: 1px 1px 1px rgb(224, 222, 222);
    /* height: 40px; */
}

.veecli-table-head {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    margin-bottom: 0.5em;
    height: 10px;
}

.veecli-warning-button {
    border-color: rgb(49, 19, 246);
    border-width: 2px;
    border-color: red;
    border-radius: 5px;
    background-color: rgb(114, 185, 154);
    border-bottom-style: groove;
    color: red;
}

.veecli-warning-button:disabled {
    color: black;
}
</style>